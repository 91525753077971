import React from 'react';
import { useRouter } from 'next/router';
import { PATH as DEALS_PATH } from '~/pages/deals';
import useItandiAccount from '~/hooks/useItandiAccount';
import { PATH as ADMIN_HOME_PATH } from '~/pages/admin';

export const Page: React.FC = () => {
  const router = useRouter();
  const itandiAccountApi = useItandiAccount();
  const { data, isLoading } = itandiAccountApi.currentAccount();

  if (isLoading) {
    return <></>;
  }
  if (router.isReady) {
    if (data != null && data.isAdmin && data.company == null) {
      // Adminアカウントでまだ代理ログインしていなければAdminのhomeに飛ぶ
      router.replace(ADMIN_HOME_PATH).then();
      return <></>;
    }
    router.replace(DEALS_PATH).then();
  }
  return <></>;
};
