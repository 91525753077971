import React from 'react';
import { NextPage } from 'next';
import { Page as Component } from '~/containers/specifics/home/Page';
import { NskHead } from '~/components/common/head/NskHead';

export const PATH = '/';

const Home: NextPage = () => (
  <>
    <NskHead title="HOME" />
    <Component />
  </>
);

export default Home;
